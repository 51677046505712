<template>
  <b-modal id="modal-checklist" hide-footer header-class="align-items-center">
    <template #modal-header="{ close }">
      <h5 class="mb-0">
        {{ form.id ? "Actualizar Plantilla" : "Crear Plantilla" }}
      </h5>
      <b-button
        variant="text"
        @click="closeModal"
        class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
        style="min-height: 24px"
      >
        <i
          class="ri-close-line hp-text-color-dark-0 lh-1"
          style="font-size: 24px"
        ></i>
      </b-button>
    </template>

    <b-form class="mb-8">
      <b-form-group
        label="Nombre de la Plantilla :"
        label-for="checklistName"
        class="mb-16"
      >
        <b-form-input
          v-model="form.name"
          id="checklistName"
          placeholder="Nombre de la Plantilla"
          :class="{ 'is-invalid': $v.form.name.$error }"
          type="text"
        ></b-form-input>
        <div v-if="$v.form.name.$error" class="invalid-feedback">
          <span v-if="!$v.form.name.required"
            >Nombre de la Plantilla es Requerida.</span
          >
        </div>
      </b-form-group>

      <b-form-group
        label="Unidad de Negocio :"
        label-for="businessUnit"
        class="mb-16"
      >
        <v-select
          id="businessUnit"
          v-model="form.business_unit"
          :options="businessUnits"
          placeholder="Selecciona la Unidad de Negocio"
          :class="{ 'is-invalid': $v.form.business_unit.$error }"
        >
          <template #no-options> Sin Resultados. </template>
        </v-select>
        <div v-if="$v.form.business_unit.$error" class="invalid-feedback">
          <span v-if="!$v.form.business_unit.required"
            >La Unidad de Negocio es Requerido.</span
          >
        </div>
      </b-form-group>

      <b-form-group
        label="Tipos de Proyecto :"
        label-for="checklistTypesProject"
        class="mb-16"
      >
        <v-select
          id="checklistTypesProject"
          label="name"
          multiple
          :reduce="(typeProject) => typeProject.id"
          v-model="form.project_type"
          :options="typesProject"
          placeholder="Selecciona Uno o Varios Tipos de Proyecto"
        >
          <template #no-options> Sin Resultados. </template>
        </v-select>
      </b-form-group>

      <b-form-group
        label="Sección :"
        label-for="section"
        class="mb-16"
      >
        <v-select
          id="section"
          v-model="form.section"
          :options="sections"
          :reduce="(option) => option.id"
          label="name"
          placeholder="Selecciona la sección"
          :class="{ 'is-invalid': $v.form.section.$error }"
        >
          <template #no-options> Sin Resultados. </template>
        </v-select>
        <div v-if="$v.form.section.$error" class="invalid-feedback">
          <span v-if="!$v.form.section.required"
            >La Sección es Requerido.</span
          >
        </div>
      </b-form-group>

      <b-form-group
        label="Descripción de la Plantilla :"
        label-for="checklistDescription"
        class="mb-16"
      >
        <b-form-textarea
          v-model="form.description"
          placeholder="Descripción de la Plantilla"
          id="checklistDescription"
          :class="{ 'is-invalid': $v.form.description.$error }"
        ></b-form-textarea>
        <div v-if="$v.form.description.$error" class="invalid-feedback">
          <span v-if="!$v.form.description.required"
            >Descripción de la Plantilla es Requerido.</span
          >
        </div>
      </b-form-group>
    </b-form>

    <div class="d-flex justify-content-end flex-wrap mt-32">
      <b-button variant="outline-info-3" @click="closeModal"> Cerrar </b-button>
      <b-button
        type="submit"
        class="ml-16"
        variant="outline-primary"
        @click="handleSubmit"
      >
        <b-spinner v-if="loading" small type="grow" class="mr-8"></b-spinner>
        {{ form.id ? "Actualizar Plantilla" : "Crear Plantilla" }}
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
const { required } = require("vuelidate/lib/validators");

export default {
  mixins: [validationMixin],
  mounted() {
    this.getTypesProject();
  },
  data() {
    return {
      loading: false,
      form: {
        id: null,
        name: "",
        business_unit: null,
        section: null,
        description: "",
        project_type: [],
      },
    };
  },
  validations: {
    form: {
      name: {
        required,
      },
      description: {
        required,
      },
      business_unit: { required },
      section: { required },
      checklist_type: {},
      project_type: {},
    },
  },
  methods: {
    ...mapActions({
      getTypesProject: "project/getTypesProject",
      updateOrCreateChecklist: "checklist/updateOrCreateChecklist",
    }),
    show(checklist) {
      this.$nextTick(() => {
        this.$v.$reset();
      });
      if (checklist) {
        this.form = {
          id: checklist.id,
          name: checklist.name,
          description: checklist.description,
          business_unit: checklist.business_unit,
          section: checklist.section.id,
          checklist_type: checklist.checklist_type
            ? checklist.checklist_type.id
            : null,
          project_type: checklist.project_type
            ? checklist.project_type.map((type) => type.id)
            : [],
        };
      }
      this.$bvModal.show("modal-checklist");
    },
    async handleSubmit() {
      this.$v.$touch();
      this.$v.form.$touch();
      if (!this.$v.form.$anyError) {
        this.loading = true;
        const { id, ...rest } = this.form;
        let response;
        if (id) {
          response = await this.updateOrCreateChecklist({
            id,
            ...rest,
          });
        } else {
          response = await this.updateOrCreateChecklist({
            ...rest,
          });
        }
        this.loading = false;
        if (response.status == 201 || response.status == 200) {
          this.$bvToast.toast(
            `Plantilla ${id ? "actualizada" : "creada"} correctamente`,
            {
              title: `Excelente`,
              variant: "primary",
              solid: false,
            }
          );
          this.closeModal();
          this.$emit("success");
        }
      }
    },
    closeModal() {
      this.$bvModal.hide("modal-checklist");
      this.form = {
        id: null,
        name: "",
        description: "",
        business_unit: null,
        checklist_type: null,
        section: null,
        project_type: [],
      };
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
  },
  computed: {
    ...mapGetters({
      typesProject: "project/typesProject",
      businessUnits: "project/businessUnits",
      typesChecklist: "checklist/typesChecklist",
      sections: "project/sections",
    }),
  },
};
</script>

<style></style>
